<template>
	<div class="imofalform2FormHeader puiformheader"></div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'imofalform2-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'imofalform2'
		};
	},
	computed: {}
};
</script>
